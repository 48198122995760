<template>
  <ErrorPage />
</template>

<script>
import ErrorPage from '@/components/ErrorPage'
export default {
  name: 'Error',
  components: { ErrorPage }
}
</script>
